<script setup>
/* eslint-disable */
import { onMounted, computed, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useFixtureStore } from '@/stores/fixture';
import { useSportPopularStore } from '@/stores/sport_popular';
import { useSocketStore } from '@/stores/socket';
import EsportFixtureDoubleMarket from './EsportFixtureDoubleMarket.vue';
import LiveFixtureDoubleMarket from './LiveFixtureDoubleMarket.vue';

const router = useRouter();
const liveSportsStore = useLiveSportsStore();
const preSportsStore = usePreSportsStore();
const liveSportOverviewStore = useLiveSportOverview();
const fixtureStore = useFixtureStore();
const sportPopularStore = useSportPopularStore();
const socketStore = useSocketStore();

const props = defineProps({
	primary_overview_market: {
		type: Object,
		required: true,
	},
	secondary_overview_market: {
		type: Object,
		required: true,
	},
});

</script>

<template>
	<div id="popular" class="tab-content">

		<div v-if="Object.keys(sportPopularStore.popularFixtures).length == 0">
			<div style="margin-top: 20px;">
				<p style="text-align: center; color: white">{{ $t('general.there_are_no_popular_events_available') }}</p>
			</div>
		</div>

		<div class="live-info-outer" v-for="(competition, index) in sportPopularStore.popularFixtures" :key="index">

			<div class="live-info-inner">
				<div class="live-info-team">
					<div class="live-match">
						<div class="team-flag">
							<img :src="'/assets' + competition[0].region_icon_url" alt="">
						</div>
						<div class="live-match-name">
							<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }}</h3>
						</div>
					</div>
				</div>

				<div class="full-time-top top-title-dash">
					<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
						<span v-if="odd != ''">{{ odd }}</span>
					</template>
				</div>

				<div class="match-goal-top top-title-dash">
					<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
						<span v-if="odd != ''">{{ odd }}</span>
					</template>
					<span class="bet-column-empty"></span>

				</div>
			</div>

			<template v-for="(fixture, index) in competition" :key="index">
				<LiveFixtureDoubleMarket v-if="fixture.live"
					:primary_overview_market="primary_overview_market"
					:secondary_overview_market="secondary_overview_market"
					:fixture="fixture"
				/>

				<EsportFixtureDoubleMarket v-if="!fixture.live"
					:primary_overview_market="primary_overview_market"
					:secondary_overview_market="secondary_overview_market"
					:fixture="fixture"
				/>

			</template>
		</div>
	</div>
</template>